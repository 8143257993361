export const environment = {
  production: true,
  PROJECT_NAME: 'Telemo',
  integration_url_id:'',
  domain_uuid_id:'',

  // TF-MAIN
  // API_URL: 'https://apps.cexlabapps.com:10707/',
  // AGENT_QUEUE_URL: 'https://apps.cexlabapps.com:20707/',
  // SOCIAL_MEDIA_URL: 'https://apps.cexlabapps.com:30707/',
  // DOMAIN_URL: 'https://apps.cexlabapps.com',
  // wsEndpoint: 'wss://apps.cexlabapps.com/main',
  // SocketIOChatUrl: 'https://apps.cexlabapps.com:9000/',

  // TELEMO
  // API_URL: 'https://mycc.telemo.io:10707/',
  // AGENT_QUEUE_URL: 'https://mycc.telemo.io:20707/',
  // SOCIAL_MEDIA_URL: 'https://mycc.telemo.io:30707/',
  // DOMAIN_URL: 'https://mycc.telemo.io',
  // wsEndpoint: 'wss://mycc.telemo.io/main',
  // SocketIOChatUrl: 'https://mycc.telemo.io:9000/',

    // VI
  // API_URL: 'https://ccaas.telemo.io:10707/',
  // AGENT_QUEUE_URL: 'https://ccaas.telemo.io:20707/',
  // SOCIAL_MEDIA_URL: 'https://ccaas.telemo.io:30707/',
  // DOMAIN_URL: 'https://ccaas.telemo.io',
  // wsEndpoint: 'wss://ccaas.telemo.io/main',
  // SocketIOChatUrl: 'https://ccaas.telemo.io:9000/',

  //TF-TEST
  // API_URL: 'https://tripfactory.callibry.com:1001/',
  // AGENT_QUEUE_URL: 'https://tripfactory.callibry.com:1002/',
  // SOCIAL_MEDIA_URL: 'https://tripfactory.callibry.com:1004/',
  // DOMAIN_URL: 'https://tripfactory.callibry.com',
  // wsEndpoint: 'wss://tripfactory.callibry.com/main',
  // SocketIOChatUrl: 'https://tripfactory.callibry.com:9000/',

  API_URL: 'https://vi.callibry.com//vi_accounting/',             //Accounting URL
  AGENT_QUEUE_URL: 'https://vi.callibry.com/vi_call_center/',     //call_center
  SOCIAL_MEDIA_URL: 'https://vi.callibry.com/vi_facebook/',  
  DOMAIN_URL: 'https://vi.callibry.com',
  wsEndpoint: 'wss://https://vi.callibry.com/main',
  SocketIOChatUrl: 'https://vi.callibry.com:9003/',

  ProxyUrl: 'telephony.telemo.io',
  ProxyPort:'7443',
  debugMode: false,
  LOG: (e) => '',
  ERROR: (e) => '',
  firebase: {
    apiKey: 'AIzaSyAvHtu5rrqKSZQg75R2MYqlNgGdMKy2YR4',
    projectId: 'modern-admin-8453e',
    authDomain: 'modern-admin-8453e.firebaseapp.com',
    appId: '1:300550201463:web:96a271cc309ac5aea86672'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU'
};
